.table-vertical-align {

    td {
        vertical-align: middle;
    }

    .aligned-image {
        position: relative;
        margin-top: -0px;

    }

}

.circleLoader {

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,.8);
    z-index: 999999;
    text-align: center;
    color: white;
    display: flex;

    .loader-wrapper {
        align-self: center;
        align-content: center;
        text-align: center;
        width: 100%;
    }

    p {
        font-size: 2rem;
        font-weight: bold;
    }
    .loader,
    .loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
    .loader {

        // margin: 60px auto;
        font-size: 10px;
        position: absolute;
        left: calc(50% - 70px);
        margin-top: -100px;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(255, 255, 255, 0.2);
        border-right: 1.1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
        border-left: 1.1em solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

}

.popup {

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    display: flex;

    .popup-background {

        background: rgba(0,0,0,.6);
        z-index: 1050;

        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .popup-content {
        position: relative;
        margin: 0.5rem;
        overflow-y: scroll;
        max-height: calc(100vh - 1rem);
        z-index: 1100;
        width: calc(100% - 2rem);
        margin-right: auto;
        margin-left: auto;
        -webkit-overflow-scrolling: touch;
        align-self: center;
        overflow-y: auto;

    }
    @media (min-width: 576px) {
        .popup-content {
            max-width: 540px;
        }
        @media (min-width: 768px) {
            .popup-content {
                max-width: 720px;
            }
        }
        @media (min-width: 992px) {
            .popup-content {
                max-width: 960px;
            }
        }
        @media (min-width: 1200px) {
            .popup-content {
                max-width: 1140px;
            }
        }

    }

    .popup-body {
        padding: 1.5rem;
        background: white;
        border-radius: 5px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        /* Lets it scroll lazy */

        .close {
            position: fixed;
            top: 20px;
            right: 20px;
            font-size: 1.2rem;
            z-index: 9999;
            color: black;
        }
    }

    .container {
        padding-right: 0;
        padding-left: 0;
        width: calc(100% - 30px);
        margin: auto;
    }

}

.noScroll {
    /* ...or body.dialogShowing */
    overflow: hidden !important;
}

.payment-content {
    // position: fixed;
    –webkit-overflow-scrolling: touch;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    z-index: 99999999999;
    overflow-y: auto;

    .col-left {
        –webkit-overflow-scrolling: touch;
        background: white;
        padding: 4rem 4rem;
        min-height: 100vh;

        .col-wrapper {
            –webkit-overflow-scrolling: touch;
            max-width: 600px;
            margin-left: auto;
        }

       
    }

    .col-right {
        –webkit-overflow-scrolling: touch;
        padding: 4rem 4rem;

        .col-wrapper {
            –webkit-overflow-scrolling: touch;
            max-width: 400px;
            margin-right: auto;
        }

        
    }

    @media(max-width: 993px) {
        

        .col-left, .col-right {
            padding: 4rem 2rem;

            .col-wrapper {
                max-width: 100% ;
            }
            
        }
    }

    @media(max-width: 600px) {
        .icons, .logo {
            –webkit-overflow-scrolling: touch;
            width: 100% !important;
            text-align: left !important;
            margin-right: auto;
        }
    } 
    

    .payment-body {
        –webkit-overflow-scrolling: touch;
        padding:  0;
       
    }
}