.StripeElement {

    display: block;
    width: 100%;
    height: calc(2.75rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    -webkit-transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    
}

.AdOns-payments {

    @media(max-width: 600px) {
        .logo {
            width: 50% !important;
        }
        .icons {
            width: 50% !important;
            
        }

        .icon-container {
            text-align: left !important;
        }
    }
}